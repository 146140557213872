<template>
  <div class="tool">
    <container>
      <div class="tool-content">
        <div class="left">
          <div class="banner">
            <img
              style="width: 100%"
              src="http://dummyimage.com/215x145/f47983"
              alt=""
              srcset=""
            />
          </div>

          <div class="tool-list">
            <div class="tool-box">
              <div class="box-left">
                <span>道具商城</span>
                <span>可开发票</span>
                <span>企业购买更多优惠</span>
                <span>招聘提效必备</span>
              </div>
            </div>

            <div class="tool-box" v-for="i in 8" :key="i">
              <div class="box-left">
                <div class="avatar">
                  <el-avatar
                    shape="square"
                    :size="80"
                    src="https://dummyimage.com/80x80/f00056"
                  ></el-avatar>
                </div>
                <div class="desc">
                  <div class="title">置顶职位</div>
                  <div class="desc-text">
                    牛人直通卡，置顶您的招聘职位，邀请牛人投简历
                  </div>
                </div>
              </div>
              <div class="box-right">
                <el-button size="medium" style="width: 80px">查看</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="score-wrap">
            <div class="title">
              <span>我的积分</span>
              <span>
                <i class="el-icon-warning-outline"></i>
              </span>
            </div>
            <div class="coin-count">
              <span>
                <i class="el-icon-coin"></i>
                789
              </span>
              <span> 积分明细> </span>
            </div>
            <div>
              <el-button size="medium" style="width: 100%">购买积分</el-button>
            </div>
          </div>

          <div class="other-wrap">
            <div class="other-row">
              <el-link :underline="false">我的优惠券</el-link>
            </div>
            <div class="other-row">
              <el-link :underline="false">购买记录</el-link>
            </div>
            <div class="other-row">
              <el-link :underline="false">开增值税普通发票</el-link>
            </div>
            <div class="other-row">
              <el-link :underline="false"
                >开增值税专用发票

                <i class="el-icon-question"></i>
              </el-link>
            </div>
          </div>

          <div class="shop-enter">
            <div class="title">
              <span>企业购买</span>
              <span>
                <i class="el-icon-headset"></i>
              </span>
            </div>
            <div class="desc">如果您需要购买合同和发票，请选择企业购买。</div>

            <div class="tags">
              <span>更优惠的企业价</span>
              <span>合同/发票 随心开</span>
              <span>道具可分配多人使用</span>
              <span>专属顾问指导</span>
            </div>

            <div class="btn-wrap">
              <el-button style="width: 100%" size="medium"
                >请销售顾问联系我</el-button
              >
            </div>

            <div class="tel-wrap">电话咨询：400 0000 0000</div>
          </div>

          <div class="charge">
            <div class="title">道具/优惠券兑换</div>

            <div class="input-wrap">
              <div class="input-wrap">
                <el-input size="medium" />
              </div>
              <div class="btn-wrap">
                <el-button size="medium">兑换</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "./Container.vue";
export default {
  components: { Container },
};
</script>

<style lang="scss" scoped>
.tool {
  padding: 20px 0;

  .tool-content {
    display: flex;

    .left {
      flex: 1 1 auto;
    }

    .right {
      flex: 0 0 auto;
      width: 300px;
      margin-left: 15px;
    }

    .banner {
      height: 80px;
      overflow: hidden;
      border-radius: 4px;
    }

    .score-wrap {
      padding: 20px;
      background: #fff;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span:nth-child(1) {
          font-size: 18px;
        }
      }

      .coin-count {
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .other-wrap {
      margin-top: 15px;
      padding: 20px;
      background: #fff;

      .other-row + .other-row {
        margin-top: 10px;
      }
    }

    .shop-enter {
      margin-top: 15px;
      padding: 20px;
      background: #fff;

      .title {
        display: flex;
        justify-content: space-between;
      }

      .desc {
        margin-top: 8px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #666;
      }

      .tags {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        span {
          font-size: 14px;
          border-radius: 2px;
          padding: 2px 6px;
          margin: 4px 0;
          background: rgba($color: #409eff, $alpha: 0.1);
          color: #409eff;
        }
      }

      .btn-wrap {
        margin: 15px 0;
      }

      .tel-wrap {
        font-size: 14px;
        text-align: center;
      }
    }

    .charge {
      margin-top: 15px;
      padding: 20px;
      background: #fff;

      .input-wrap {
        margin: 15px 0;
        display: flex;
        align-items: center;
      }
    }

    .tool-list {
      margin-top: 15px;

      .tool-box {
        padding: 30px;
        background: #fff;

        display: flex;
        justify-content: space-between;
        align-items: center;

        & + .tool-box {
          border-top: 1px solid #ededed;
        }

        &:nth-child(1) {
          .box-left {
            span + span {
              margin-left: 15px;
            }

            span:nth-child(1) {
              font-size: 22px;
            }

            span:nth-child(n + 2) {
              display: inline-block;
              padding: 4px 10px;
              background: rgba($color: #409eff, $alpha: 0.1);
              color: #409eff;
              font-size: 14px;
              border-radius: 2px;
            }
          }
        }

        .box-left {
          flex: 1 1 auto;
          display: flex;
          align-items: center;

          .avatar {
            margin-right: 20px;
          }

          .desc {
            .title {
              font-size: 18px;
              margin-bottom: 10px;
            }

            .desc-text {
              font-size: 14px;
              color: #999;
            }
          }
        }
      }
    }
  }
}
</style>